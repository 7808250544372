@font-face {
  font-family: "Baskerville";
  src: url("../fonts/regular/Baskerville.woff2") format("woff2"),
    url("../fonts/regular/Baskerville.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Baskerville";
  src: url("../fonts/bold/BaskervilleBold.woff2") format("woff2"),
    url("../fonts/bold/BaskervilleBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Baskerville";
  src: url("../fonts/light/BaskervilleLight.woff2") format("woff2"),
    url("../fonts/light/BaskervilleLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
